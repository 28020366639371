import type { QueryResult } from '@apollo/client';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import type {
  Maybe,
  Revision,
  RevisionConnection,
  BlogTopicMessage,
  Message
} from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import IdConverter from '@aurora/shared-utils/graphql/IdConverter/IdConverter';
import { getLog } from '@aurora/shared-utils/log';
import { useContext } from 'react';
import type {
  ContextMessageFragment,
  MessageRevisionsQuery,
  MessageRevisionsQueryVariables,
  MessageViewFragment
} from '../../types/graphql-types';
import messageRevisionsQuery from './MessageRevisions.query.graphql';

const log = getLog(module);

/**
 * Checks to see if the preview mode flag is set or the current page is Manage Content.
 */
function useIsPreviewMode(): boolean {
  const { router } = useEndUserRoutes();
  return router.getUnwrappedQueryParam(EndUserQueryParams.PREVIEW_MESSAGE) === 'true';
}

/**
 * Checks to see if a specific revision is provided to restore history of a message.
 */
function useSpecificRevision(): string {
  const { router } = useEndUserRoutes();
  return router.getUnwrappedQueryParam(EndUserQueryParams.REVISION_ID);
}

/**
 * Get the first revision in the supplied connection
 * @param revisions
 */
function getFirstRevision(revisions: RevisionConnection): Maybe<Revision> | undefined {
  return revisions?.edges?.[0]?.node;
}

/**
 * Get the message from the revision.  This merges any versioned data from the Revision object into the returned message, since
 * the referenced message may come from cache and have data for a different version.
 * @param revision
 */
function getRevisionMessage(revision: Revision): Message | BlogTopicMessage {
  if (!revision.message) {
    throw new Error(
      'Revision is missing a message.  Ensure the GraphQL query is selecting for the revision message.'
    );
  }
  const { message } = revision;
  const {
    revisionNum,
    body,
    subject,
    rawBody,
    introduction,
    teaser,
    rawTeaser,
    coverImageProperties,
    attachments
  } = revision;
  if (coverImageProperties === undefined) {
    // merge versionable data into message data
    return {
      ...(message as Message),
      revisionNum,
      subject,
      body,
      rawBody,
      introduction,
      teaser,
      rawTeaser,
      attachments
    };
  }
  // merge versionable data into message data
  return {
    ...(message as BlogTopicMessage),
    revisionNum,
    subject,
    body,
    rawBody,
    introduction,
    teaser,
    rawTeaser,
    coverImageProperties,
    attachments
  };
}

function useMessageRevisionsQuery(
  variables: MessageRevisionsQueryVariables,
  skip = false
): QueryResult<MessageRevisionsQuery> {
  const isPreviewMode = useIsPreviewMode();
  return useQueryWithTracing<MessageRevisionsQuery, MessageRevisionsQueryVariables>(
    module,
    messageRevisionsQuery,
    {
      variables: {
        ...variables,
        constraints: {
          isPublished: {
            eq: !isPreviewMode
          }
        }
      },
      skip: !variables?.id || skip
    }
  );
}

/**
 * A hook to get the specified message, or, if in preview mode, the latest revision of the specified message.
 * @param message the message to return if condition is not satisfied.
 * @param useLatestRevision if specified, use the latest revision, otherwise return the provided message.
 * @param queryVariables if specified, additional variables for the MessageRevisionsQuery.
 * If this argument is not provided, the default behavior is to provide the preview message only in preview mode where
 * the current user can edit.
 */
function useCurrentOrPreviewMessage(
  message: MessageViewFragment | ContextMessageFragment,
  useLatestRevision = false,
  queryVariables?: MessageRevisionsQueryVariables
): ContextMessageFragment | MessageViewFragment | null {
  const isPreviewMode = useIsPreviewMode();
  const tenant = useContext(TenantContext);
  const { isAnonymous } = useRegistrationStatus();

  const queryForRevisions =
    message?.id &&
    !IdConverter.isOptimistic(tenant, message?.id) &&
    !isAnonymous &&
    (useLatestRevision === true || isPreviewMode);

  const variables = {
    id: message?.id,
    ...queryVariables,
    revisionsFirst: 1,
    useRevisionMessage: true,
    useContributors: true
  };
  const { loading, data, error } = useMessageRevisionsQuery(variables, !queryForRevisions);
  if (queryForRevisions) {
    if (error) {
      log.error(error, 'Error querying for revisions with variables: %O', variables);
    } else if (loading || !data) {
      return null;
    } else {
      return getRevisionMessage(getFirstRevision(data?.message?.revisions as RevisionConnection));
    }
  }
  return message;
}

export {
  useIsPreviewMode,
  useMessageRevisionsQuery,
  getFirstRevision,
  useCurrentOrPreviewMessage,
  useSpecificRevision,
  getRevisionMessage
};
