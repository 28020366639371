import { EndUserPages, EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import { useContext } from 'react';
import useEndUserRoutes from '../routes/useEndUserRoutes';
import useCommunitySsoProperties from './community/useCommunitySsoProperties';
import TenantContext from './context/TenantContext';
import useAuthFlow from './useAuthFlow';
import useIsomorphicRedirect from './useIsomorphicRedirect';
import useIsomorphicRedirectByUrl from './useIsomorphicRedirectByUrl';

/**
 * Hook that redirects to Login Page or to sso client if sso is enabled with the provided path as referer.
 * This supports redirecting server-side or client-side depending on how the page was accessed.
 *
 * @param shouldRedirect whether to redirect
 *
 * @author Agustin Sosa
 */
export default function useLoginRedirect(shouldRedirect = false): void {
  const { router } = useEndUserRoutes();
  const { data, loading: ssoPropertiesLoading } = useCommunitySsoProperties(module);
  const tenant = useContext(TenantContext);
  const { multiAuthEnabled } = tenant.publicConfig;
  const { ssoProperties } = data?.community || {};
  const ssoEnabled = checkPolicy(ssoProperties?.ssoEnabled);
  const nextPage = router.asPath;
  const { getSsoAuthUrl } = useAuthFlow();
  const finalUrl = getSsoAuthUrl(nextPage);

  useIsomorphicRedirect(shouldRedirect && !ssoPropertiesLoading && !ssoEnabled, {
    route: EndUserPages.LoginPage,
    params: null,
    query: {
      [EndUserQueryParams.NEXT_PAGE]: nextPage
    }
  });

  useIsomorphicRedirectByUrl(
    shouldRedirect && !ssoPropertiesLoading && (ssoEnabled || multiAuthEnabled),
    finalUrl
  );
}
