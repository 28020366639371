export enum ThemeEditorIconFlow {
  LANDING = 'landing',
  CUSTOMIZE = 'customize'
}

export enum ThemeEditorFontFlow {
  LANDING = 'landing',
  CHOOSE_FONT = 'chooseFont',
  ADD_CUSTOM_FONT = 'addCustomFont',
  CUSTOM_FONT_MENU = 'customFontMenu',
  UPLOAD_CUSTOM_FONT = 'uploadCustomFont',
  EDIT_CUSTOM_FONT_GOOGLE = 'editCustomFontGoogle',
  EDIT_CUSTOM_FONT_SERVER = 'editCustomFontServer'
}

export enum AttachmentViewVariant {
  CHIP = 'chip'
}

export enum NodeViewVariant {
  INLINE = 'inline',
  CARD = 'card'
}

export enum MessageViewVariant {
  STANDARD = 'standard',
  INLINE = 'inline',
  CARD = 'card',
  TABLE_ROW = 'tableRow'
}

export enum ModeratedMessageViewVariant {
  TABLE_ROW = 'tableRow',
  INLINE = 'inline'
}

export enum FilterEventsViewVariant {
  TABLE_ROW = 'tableRow',
  INLINE = 'inline'
}

export enum AbuseReportedMessageViewVariant {
  TABLE_ROW = 'tableRow',
  INLINE = 'inline'
}

export enum AbuseReportedPrivateMessageViewVariant {
  TABLE_ROW = 'tableRow',
  INLINE = 'inline'
}

export enum AbuseReportedUserViewVariant {
  TABLE_ROW = 'tableRow',
  INLINE = 'inline'
}

export enum RejectedPrivateMessageViewVariant {
  TABLE_ROW = 'tableRow',
  INLINE = 'inline'
}

export enum UserViewVariant {
  INLINE = 'inline',
  CARD = 'card',
  TABLE_ROW = 'tableRow'
}

export enum UserInviteViewVariant {
  TABLE_ROW = 'tableRow'
}

export enum MemberViewVariant {
  MEMBER_VIEW_TABLE_ROW = 'memberViewTableRow'
}

export enum TagViewVariant {
  CHIP = 'chip',
  INLINE = 'inline',
  TABLE_ROW = 'tableRow'
}

export enum MessageListVariant {
  LIST = 'list',
  CARD = 'card'
}

export enum NotificationViewVariant {
  INLINE = 'inline'
}

export enum PrivateConversationViewVariant {
  STANDARD = 'standard',
  INLINE = 'inline'
}

export enum PrivateNoteViewVariant {
  STANDARD = 'standard'
}

export enum BadgeSetViewVariant {
  CARD = 'card'
}

export enum BadgeViewVariant {
  STANDARD = 'standard',
  MAIN = 'main',
  ICON = 'icon',
  INLINE = 'inline'
}

export enum IdeaStatusViewVariant {
  INLINE = 'inline',
  CARD = 'card'
}

export enum ViewSize {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg'
}

export enum UserViewSize {
  SM = 'sm',
  MD = 'md'
}

export enum CoverImageTitlePosition {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
  OVERLAY = 'OVERLAY'
}

export enum CoverImageStyle {
  WIDE = 'WIDE',
  STANDARD = 'STANDARD'
}

export enum MessageViewStandardAvatarPosition {
  TOP = 'top',
  LEFT = 'left'
}

export enum ItemListType {
  MESSAGE = 'message',
  USER = 'user',
  NODE = 'node'
}

export enum UserTableCellType {
  AVATAR = 'avatar',
  LOGIN = 'login',
  ROLE = 'role',
  LOGIN_AND_ROLE = 'loginAndRole',
  RANK = 'rank',
  JOIN_DATE = 'joinDate',
  MESSAGE_COUNT = 'messageCount'
}

export enum UserInviteTableCellType {
  RECIPIENT = 'recipient',
  INVITER = 'inviter',
  INVITE_TIME = 'inviteTime',
  ACTIONS = 'actions'
}

export enum MembershipTableCellType {
  AVATAR = 'avatar',
  LOGIN = 'login',
  ROLE = 'role',
  DATE = 'date',
  ACTION_MENU = 'actionMenu'
}

export enum RteToolbarLocation {
  TOP = 'top',
  BOTTOM = 'bottom'
}

export enum ItemType {
  ATTACHMENT = 'attachment',
  BADGE = 'badge',
  BADGE_SET = 'badgeSet',
  MESSAGE = 'message',
  MODERATED_MESSAGE = 'moderatedMessage',
  NODE = 'node',
  NOTIFICATION = 'notification',
  PRIVATE_CONVERSATION = 'privateConversation',
  PRIVATE_NOTE = 'privateNote',
  SUBSCRIPTION = 'subscription',
  TAG = 'tag',
  USER = 'user',
  MESSAGE_SEARCH = 'messageSearch',
  USER_INVITE = 'userInvite',
  ABUSE_REPORTED_MESSAGE = 'abuseReportedMessage',
  NODE_SEARCH = 'nodeSearch',
  USER_SEARCH = 'userSearch',
  BAN_RULE = 'banRule',
  FILTER_EVENTS = 'filter_events',
  ABUSE_REPORTED_PRIVATE_MESSAGE = 'abuseReportedPrivateMessage',
  ABUSE_REPORTED_USER = 'abuseReportedUser',
  REJECTED_PRIVATE_MESSAGE = 'rejectedPrivateMessage',
  IDEA_STATUS = 'ideaStatus',
  GUIDE = 'guide'
}

export enum MessageActionMenuItem {
  EDIT = 'edit',
  EDIT_INLINE = 'editInline',
  COPY_PUBLISHED_URL = 'copyPublishedUrl',
  DELETE = 'delete',
  VIEW_TOGGLE = 'viewToggle',
  COPY_LINK = 'copyLink',
  SUBSCRIBE = 'subscribe',
  TURN_OFF_COMMENTS = 'turnOffComments',
  BLOCK_EDITS = 'blockEdits',
  MOVE = 'move',
  MOVE_COMMENT = 'moveComment',
  REPORT_ABUSE = 'reportAbuse',
  MARK_AS_SPAM = 'markAsSpam',
  ARTICLE_HISTORY = 'articleHistory',
  HIDE_SPAM = 'hideSpam',
  DELETE_DRAFT = 'deleteDraft',
  CONFIRM_ABUSE = 'confirmAbuse',
  IGNORE_ABUSE = 'IgnoreAbuse',
  REJECT = 'reject',
  MARK_AS_NOT_ABUSE = 'markAsNotAbuse',
  MARK_AS_NOT_SPAM = 'markAsNotSpam',
  MARK_AS_APPROVED = 'markAsApproved',
  SEND_MESSAGE = 'sendMessage',
  BAN_MEMBER = 'banMember',
  EDIT_BAN_MEMBER = 'editBanMember',
  UPDATE_STATUS = 'updateStatus',
  INVITE = 'invite',
  ESCALATE_TO_SALESFORCE = 'escalateToSalesforce'
}

export enum TagActionMenuItem {
  EDIT = 'edit',
  DELETE = 'delete'
}

export enum MessageEditorFormField {
  SUBJECT = 'subject',
  BODY = 'body',
  ATTACHMENTS = 'attachments',
  COVERIMAGE_SUBJECT = 'coverImageSubject',
  TAGS = 'tags',
  TEASER = 'teaser',
  SCHEDULE_PUBLISH_TIME = 'schedulePublishTime',
  INTRODUCTION = 'introduction',
  SEO_TITLE = 'seoTitle',
  SEO_DESCRIPTION = 'seoDescription',
  CANONICAL_URL = 'canonicalUrl',
  REVISION_NOTE = 'revisionNote',
  CONTRIBUTORS_LIST = 'contributorsList',
  NOTIFY_FOLLOWERS = 'notifyFollowers',
  OCCASION_TYPE = 'occasionType',
  OCCASION_START_TIME = 'occasionStartTime',
  OCCASION_END_TIME = 'occasionEndTime',
  OCCASION_VENUE = 'occasionVenue',
  OCCASION_LIVE_URL = 'occasionLiveUrl',
  OCCASION_LOCATION = 'occasionLocation',
  COAUTHORS_LIST = 'coAuthorsList',
  AUTHOR_FOR_BLOG = 'authorForBlog',
  FEATURED_GUESTS = 'featuredGuests',
  CUSTOM_FIELDS = 'custom',
  NOTIFY_AUTHOR = 'notifyAuthor',
  NOTIFY_PARTICIPANTS = 'notifyParticipants',
  EDIT_REASON = 'editReason'
}

export enum MessageEditorHelpTabs {
  SHORTCUTS = 'shortcuts',
  KEYBOARD_NAVIGATION = 'keyboardNavigation',
  MARKDOWN_SYNTAX = 'markdownSyntax'
}

export enum WorkflowAction {
  SAVE_DRAFT = 'SAVE_DRAFT',
  PUBLISH = 'PUBLISH'
}

export enum MessageEditorFormAction {
  CANCEL = 'cancel',
  DRAFT = 'draft',
  PUBLISH = 'publish',
  SCHEDULE_PUBLISH = 'schedulePublication',
  SUBMIT_FOR_REVIEW = 'submitForReview',
  EDIT_DRAFT = 'editDraft',
  SUBMIT_FOR_PUBLICATION = 'submitForPublication',
  RECALL = 'recall',
  RETURN_TO_AUTHOR = 'returnToAuthor',
  RETURN_TO_REVIEW = 'returnToReview',
  EDIT_SCHEDULE = 'editSchedule',
  REMOVE = 'remove'
}

export type PageEditZoomValue = 50 | 60 | 70 | 80 | 90 | 100;

export enum PagePreviewType {
  DESKTOP = 'DESKTOP',
  TABLET = 'TABLET',
  PHONE = 'PHONE'
}

export enum UserKudosType {
  RECEIVED = 'kudosReceived',
  GIVEN = 'kudosGiven'
}

export enum NodeHeaderVariant {
  SM = 'sm',
  MD = 'md',
  LG = 'lg'
}

export enum NodeHeaderOptionsToDisplay {
  BOTH = 'both',
  TITLE_ONLY = 'title',
  NONE = 'none'
}

export enum NodeHeaderContentWidth {
  WIDE = 'wide',
  MEDIUM = 'medium',
  NARROW = 'narrow'
}

export enum NodeHeaderLayout {
  EQUAL_WIDTH = 'equal-width',
  WIDE_LEFT = 'wide-left',
  WIDE_RIGHT = 'wide-right'
}

export enum MessageFeedbackDisplayType {
  BUTTON = 'button',
  ICON = 'icon'
}

export enum MessageFeedbackValue {
  NEUTRAL = -1,
  UNHELPFUL = 0,
  HELPFUL = 1
}

export enum MembershipAction {
  JOIN_MEMBERSHIP_NODE = 'join',
  LEAVE_MEMBERSHIP_NODE = 'leave',
  JOINED_MEMBERSHIP_NODE = 'joined',
  REQUEST_TO_JOIN = 'requestToJoin',
  REQUEST_SENT = 'requestSent',
  MANAGE_MEMBERS = 'manageMembers',
  ACCEPT_INVITE = 'acceptInvite',
  REMOVE_MEMBER = 'removeMember',
  APPROVE_MEMBERSHIP_REQUEST = 'approveMembershipRequest',
  DENY_MEMBERSHIP_REQUEST = 'denyMembershipRequest',
  EDIT_MEMBER_ROLE = 'editMemberRole',
  CANCEL_INVITE = 'cancelInvite',
  EDIT_GROUP_HUB = 'edit',
  RESEND_INVITE = 'resendInvite',
  SEND_INVITE = 'sendInvite',
  EDIT_GROUP_HUB_SETTINGS = 'editGroupHubSettings',
  MANAGE_GROUP_HUB_CONTENT = 'manageGroupHubContent'
}

export enum SubscriptionActionVariant {
  BUTTON = 'button',
  ICON = 'icon',
  DROPDOWN_ITEM = 'dropdownItem'
}

export enum CategoryAction {
  EDIT_CATEGORY_SETTINGS = 'editCategorySettings',
  MANAGE_CATEGORY_CONTENT = 'manageCategoryContent'
}

export enum BoardAction {
  EDIT_BOARD_SETTINGS = 'editBoardSettings',
  MANAGE_BOARD_CONTENT = 'manageBoardContent'
}

export enum ManageMembersTab {
  MEMBERS = 'members',
  REQUESTS = 'requests',
  PENDING_INVITES = 'pendingInvitations'
}

export enum ApolloQueryCacheKey {
  USER_LIST_FOR_NODE_BY_JOIN_DATE = 'userListForNodeByJoinDate',
  MEMBER_LIST_FOR_MANAGE_MEMBERS = 'memberListForManageMembers',
  MEMBER_LIST_FOR_NODE_MODAL = 'memberListForNodeModal',
  MEMBER_COUNT = 'memberCount',
  MEMBERSHIP_INVITES_LIST = 'membershipInvitesList',
  PRIVATE_CONVERSATIONS_INBOX_LIST = 'privateConversationsInboxList',
  PRIVATE_CONVERSATIONS_SENT_LIST = 'privateConversationsSentList',
  PRIVATE_CONVERSATIONS_SEARCH_LIST = 'privateConversationsSearchList',
  PRIVATE_CONVERSATION_READ_NOTES_LIST = 'privateConversationReadNotesList',
  PRIVATE_CONVERSATION_UNREAD_NOTES_LIST = 'privateConversationUnReadNotesList',
  USER_LIST_FOR_NODE_BY_TOP_CONTRIBUTORS = 'userListForNodeByTopContributorsWidget',
  NODE_STRUCTURE = 'nodeStructureForPlacesWidget',
  FOLLOWS_AND_NOTIFICATIONS_LIST = 'followsAndNotificationsList',
  TOPIC_REPLY_LIST = 'TopicReplyList',
  REPLY_LIST = 'ReplyList'
}

export enum UserSettingsTab {
  PERSONAL = 'personal',
  PREFERENCES = 'preferences',
  FOLLOWS_AND_NOTIFICATIONS = 'followsAndNotifications',
  SECURITY_AND_ACCOUNT = 'securityAndAccount'
}

export enum MemberRole {
  OWNER = 'Owner',
  MEMBER = 'Member'
}

export enum InboxActionMenuItem {
  SHOW_ALL = 'showAll',
  MARK_ALL_AS_READ = 'markAllAsRead',
  SHOW_UNREAD_ONLY = 'showUnreadOnly'
}

export enum InboxViewType {
  INBOX = 'inbox',
  OUTBOX = 'outbox',
  SEARCH = 'search'
}

export enum PrivateConversationCreationFormField {
  IS_BROADCAST = 'isBroadcast',
  RECIPIENTS = 'recipients',
  SUBJECT = 'subject',
  BODY = 'body'
}

export enum PrivateConversationStateFilter {
  READ = 'read',
  UNREAD = 'unread',
  ALL = 'all'
}

export enum PrivateConversationMenuActions {
  VIEW_RECIPIENTS = 'viewRecipients',
  DELETE = 'delete',
  REPORT_INAPPROPRIATE = 'reportAsInappropriate'
}

export enum Breakpoint {
  XS = 0,
  SM = 576,
  MD = 768,
  LG = 992,
  XL = 1260
}

export enum PrivateConversationListState {
  EMPTY = 'empty',
  FULL = 'full',
  LOADING = 'loading'
}

export enum MessageTimestamp {
  POST_TIME = 'postTime',
  LAST_PUBLISHED_TIME = 'lastPublishTime',
  LAST_POSTING_ACTIVITY_TIME = 'conversation.lastPostingActivityTime',
  LAST_POST_TIME = 'conversation.lastPostTime',
  REJECTED_TIME = 'moderationData.rejectTime'
}

export enum MessageViewToggleAction {
  EXPAND = 'expand',
  COLLAPSE = 'collapse'
}

export enum ShowTextContent {
  ALWAYS = 'always',
  NEVER = 'never',
  MOBILE = 'mobile',
  DESKTOP = 'desktop'
}

export enum TopContributorWidgetSort {
  TOPICS_COUNT = 'topicsCount',
  MESSAGES_COUNT = 'messagesCount',
  ACCEPTED_SOLUTIONS_COUNT = 'acceptedSolutionCount',
  KUDOS_RECEIVED_COUNT = 'kudosReceivedCount'
}

export enum AttachmentsContainerType {
  MESSAGE = 'message',
  NOTE = 'note'
}

export enum MessageState {
  AWAITING_REVIEW = 'awaitingReview',
  AWAITING_PUBLICATION = 'awaitingPublication'
}

export enum TopContributorWidgetTimePeriod {
  ALL = 'all',
  ONE_DAY = 'one_day',
  ONE_WEEK = 'one_week',
  ONE_MONTH = 'one_month',
  THREE_MONTHS = 'three_months',
  SIX_MONTHS = 'six_months',
  ONE_YEAR = 'one_year'
}

export enum SubscriptionAction {
  SUBSCRIBE = 'subscribe',
  UNSUBSCRIBE = 'unsubscribe',
  SUBSCRIBED = 'subscribed',
  FULL_SUBSCRIPTION = 'fullSubscription',
  PARTIAL_SUBSCRIPTION = 'partialSubscription'
}

export enum MessageTableCellType {
  SUBJECT = 'subject',
  AUTHOR = 'author',
  TIME = 'time',
  VERSION = 'version',
  STATUS = 'status',
  KUDOS_COUNT = 'kudosCount',
  VIEW_COUNT = 'viewCount',
  REPLIES_COUNT = 'repliesCount',
  WORKFLOW_STATE_ACTION = 'workflowStateAction',
  PUBLISHED_STATE = 'publishedState',
  REJECT_TIME = 'rejectTime',
  REJECT_REASON = 'rejectReason',
  MODERATOR = 'moderator',
  MESSAGE_ACTION = 'messageAction'
}

export enum TagTableCellType {
  TAG_NAME = 'text',
  POST_COUNT = 'postCount',
  FOLLOWERS_COUNT = 'followersCount',
  LAST_USED = 'time',
  TAG_ACTIONS = 'tagActions'
}

export enum ModeratedMessageTableCellType {
  SUBJECT = 'subject',
  AUTHOR = 'author',
  REPORT_DATE = 'reportDate',
  MODERATED_BY = 'moderatedBy',
  SPAM_ACTIONS = 'spamActions'
}

export enum AbuseReportedMessageTableCellType {
  SUBJECT = 'subject',
  AUTHOR = 'author',
  REPORT_DATE = 'reportedDate',
  REPORTERS_COUNT = 'reportersCount',
  VIEWS_COUNT = 'viewsCount',
  KUDOS_COUNT = 'kudosCount',
  REPLIES_COUNT = 'repliesCount',
  ABUSE_ACTIONS = 'abuseActions'
}

export enum AbuseReportedPrivateMessageTableCellType {
  SUBJECT = 'subject',
  SENDER = 'sender',
  REPORT_DATE = 'reportedDate',
  REPORTERS_COUNT = 'reportersCount',
  ABUSE_ACTIONS = 'abuseActions'
}

export enum RejectedPrivateMessageTableCellType {
  SUBJECT = 'subject',
  AUTHOR = 'author',
  REJECTED_DATE = 'rejectedDate',
  REJECT_REASON = 'rejectReason',
  MODERATOR = 'moderator',
  MESSAGE_ACTIONS = 'messageActions'
}

export enum FilterEventsTableCellType {
  SUBJECT = 'subject',
  AUTHOR = 'author',
  REPORT_DATE = 'reportDate',
  FILTER_TYPE = 'filterType',
  FILTER_ACTIONS = 'filterActions',
  ACTIONS = 'actions'
}

export enum AbuseReportedUserTableCellType {
  USERNAME = 'username',
  USERID = 'userId',
  EMAIL = 'email',
  REPORT_DATE = 'reportedDate',
  REPORTERS_COUNT = 'reportersCount',
  ABUSE_ACTIONS = 'actions'
}

export enum BrightCoveInfo {
  BRIGHTCOVE_ACCOUNT_ID = '6057984923001'
}

export enum MessageStatus {
  ALL = 'All',
  DRAFT = 'Draft',
  AWAITING_REVIEW = 'AwaitingReview',
  AWAITING_PUBLICATION = 'AwaitingPublication'
}

export enum MessageContentWorkflowState {
  DRAFT = 'draft',
  IN_REVIEW = 'in_review',
  IN_APPROVAL = 'in_approval'
}

export enum ContentType {
  BLOGS = 'Blogs',
  KNOWLEDGEBASES = 'Knowledge Bases'
}

export enum LastActivityTimePrependText {
  UPDATED = 'updated',
  LATEST_ACTIVITY = 'latestActivity',
  NONE = 'none'
}

export enum PublishedState {
  PUBLISH = 'Published',
  POST_PUBLISH = 'Post Published'
}

export enum FollowTableCellType {
  CONTENT = 'content',
  LAST_ACTIVITY = 'lastActivity',
  ACTION_MENU = 'actionMenu'
}

export enum FollowViewVariant {
  TABLE_ROW = 'tableRow'
}

export enum PublishedStatus {
  PUBLISH = 'published',
  UNPUBLISHED = 'unpublished'
}

export enum UserSettingsKeys {
  NOTIFICATIONS = 'notifications',
  EMAIL_NOTIFICATIONS = 'emailNotifications'
}

export enum EmailDeliveryFrequency {
  NEVER = 'never',
  IMMEDIATE = 'immediate',
  DAILY = 'daily',
  WEEKLY = 'weekly'
}

export enum CasePortalEmailDeliveryFrequency {
  NEVER = 'never',
  IMMEDIATE = 'immediate'
}

export enum RevisionFrequency {
  NONE = 'none',
  ALL = 'all',
  MAJOR = 'major'
}

export enum SubscriptionType {
  BADGE = 'badge',
  BLOG_TKB_EVENTS = 'blog_tkb_events_comment',
  BOARD = 'board',
  KUDOS = 'kudos',
  LABEL = 'label',
  MENTIONS = 'mentions',
  MESSAGE = 'message',
  MESSAGE_MENTIONS = 'message_mentions',
  TKB = 'tkb',
  TOPIC = 'topic',
  SOLUTIONS = 'solutions'
}

export enum RejectReason {
  SPAM = 'spam',
  ABUSE = 'abuse',
  OTHER = 'other'
}

export enum BanDurations {
  TWELVE_HOURS = 720,
  ONE_DAY = 1440,
  SEVEN_DAYS = 10080,
  FOURTEEN_DAYS = 20160,
  THIRTY_DAYS = 43200,
  PERMANENT = 0,
  CUSTOM = -1
}

export enum BanMetrics {
  DAYS = 1440,
  HOURS = 60,
  MINUTES = 1
}

export enum TagInlineMetadata {
  LAST_ACTIVITY_TIME = 'lastActivityTime',
  POST_COUNT = 'postCount',
  NONE = 'none'
}

export enum BanRuleTableCellType {
  USERNAME = 'username',
  USER_ID = 'user_id',
  EMAIL = 'email',
  IP_ADDRESS = 'ip_address',
  END_DATE = 'end_date',
  BANNED_BY = 'banned_by',
  ACTIONS = 'actions'
}

export enum BanRuleViewVariant {
  TABLE_ROW = 'tableRow',
  INLINE = 'inline'
}

export enum ReportedBy {
  MEMBER = 'member',
  CONTENT_FILTER = 'contentFilter'
}

export enum EventEntityType {
  FORUMS = 'forums',
  BLOGS = 'blogs',
  IDEAS = 'ideas',
  OCCASIONS = 'occasions',
  KNOWLEDGE_BASES = 'knowledgeBases',
  PRIVATE_MESSAGES = 'privateMessages',
  MEMBER_PROFILE_INFORMATION = 'memberProfileInformation'
}

export enum ReportedEntityType {
  POSTS_AND_REPLIES = 'postsAndReplies',
  PRIVATE_MESSAGES = 'privateMessages',
  MEMBERS = 'members'
}

/**
 * Types of Anonymous user actions
 */
export enum AnonAction {
  KUDOS = 'kudos',
  REPORT_ABUSE = 'reportAbuse',
  CREATE_POST = 'createPost',
  EVENT = 'Event'
}

/**
 * Co-authors and contributors workflow states
 */
export enum CoAuthorsAndContributorsWorkflow {
  ADD_CO_AUTHOR = 'add_co_author',
  REMOVE_CO_AUTHOR = 'remove_co_author',
  ADD_CONTRIBUTOR = 'add_contributor',
  REMOVE_CONTRIBUTOR = 'remove_contributor'
}

/**
 * ARIA live regions
 */
export enum AriaLiveRegions {
  POLITE = 'polite',
  ASSERTIVE = 'assertive',
  OFF = 'off'
}

export enum CasePortalTableCell {
  CASE_NUMBER = 'caseNumber',
  TITLE = 'title',
  STATUS = 'status',
  ORIGIN = 'origin',
  CREATE_DATE = 'createdDate',
  LAST_UPDATE_DATE = 'lastUpdateDate',
  REPORTED_BY = 'reportedBy'
}

export enum SupervisorView {
  ACCOUNT_CASES = 'accountCases',
  MY_CASES = 'myCases'
}

export enum ReportReason {
  ALL = 'All',
  HARASSMENT = 'Harassment',
  HATE_SPEECH = 'Hate Speech',
  MISLEADING_OR_FALSE_INFORMATION = 'Misleading or False information',
  NUDITY_OR_SEXUAL_ACTIVITY = 'Nudity or Sexual activity',
  PLAGIARISM = 'Plagiarism',
  SCAM_OR_FRAUD = 'Scam or Fraud',
  SPAM = 'Spam',
  SOMETHING_ELSE = 'Something else'
}

export enum ReportedFor {
  ALL = 'All',
  AVATAR = 'Avatar',
  BIO = 'Bio',
  USERNAME = 'Username',
  NAME = 'Name',
  EMAIL = 'Email',
  OTHER = 'Other'
}

export enum OccasionZoomMeetingVariant {
  LARGE = 'large',
  SMALL = 'small'
}

export enum GuideViewVariant {
  INLINE = 'inline',
  CARD = 'card'
}
