import { getEntityScopeForPage } from '@aurora/shared-client/helpers/routes/EndUserPageHelper';
import { pageDescriptorUrlPath } from '@aurora/shared-client/routes/PageDescriptorRoute';
import { EntityType } from '@aurora/shared-types/nodes/enums';
import { PageDescriptor } from '@aurora/shared-types/pages';
import { EndUserComponent, EndUserPages } from '@aurora/shared-types/pages/enums';
import {
  manageUsersPageRedirectBehaviour,
  noRedirectBehavior,
  userRequiredRedirectBehavior
} from '@aurora/shared-types/redirects/pageRedirect';
import dynamic from 'next/dynamic';
import type { WidgetDescriptor } from '../components/common/Widget/types';
import type { BannerWidgetProps } from '../components/community/BannerWidget/types';
import { endUserModuleRegistry } from './endUserModuleRegistry';
import { Feature } from './enums';
import type { CommunityFeature } from './index';

/**
 * Feature class for blogs that holds the user specific attributes
 * @author Manish Shrestha
 */
export class UsersFeature implements CommunityFeature<Feature.USERS> {
  getFeature(): Feature.USERS {
    return Feature.USERS;
  }

  getPageDescriptors(): PageDescriptor<BannerWidgetProps>[] {
    return [
      new PageDescriptor(
        EndUserPages.UserPage,
        endUserModuleRegistry[EndUserComponent.USER_PAGE],
        pageDescriptorUrlPath[EndUserPages.UserPage],
        getEntityScopeForPage(EntityType.COMMUNITY),
        noRedirectBehavior,
        true
      ),
      new PageDescriptor(
        EndUserPages.ManageUsersPage,
        endUserModuleRegistry[EndUserComponent.MANAGE_USERS_PAGE],
        pageDescriptorUrlPath[EndUserPages.ManageUsersPage],
        getEntityScopeForPage(EntityType.COMMUNITY),
        manageUsersPageRedirectBehaviour
      ),
      new PageDescriptor(
        EndUserPages.LoginPage,
        endUserModuleRegistry[EndUserComponent.LOGIN_PAGE],
        pageDescriptorUrlPath[EndUserPages.LoginPage],
        getEntityScopeForPage(EntityType.COMMUNITY),
        noRedirectBehavior,
        false,
        null,
        true
      ),
      new PageDescriptor(
        EndUserPages.RegistrationPage,
        endUserModuleRegistry[EndUserComponent.REGISTRATION_PAGE],
        pageDescriptorUrlPath[EndUserPages.RegistrationPage],
        getEntityScopeForPage(EntityType.COMMUNITY),
        noRedirectBehavior,
        false,
        null,
        true
      ),
      new PageDescriptor(
        EndUserPages.ForgotPasswordPage,
        endUserModuleRegistry[EndUserComponent.FORGOT_PASSWORD_PAGE],
        pageDescriptorUrlPath[EndUserPages.ForgotPasswordPage],
        getEntityScopeForPage(EntityType.COMMUNITY),
        noRedirectBehavior,
        false,
        null,
        true
      ),
      new PageDescriptor(
        EndUserPages.ResetPasswordPage,
        endUserModuleRegistry[EndUserComponent.RESET_PASSWORD_PAGE],
        pageDescriptorUrlPath[EndUserPages.ResetPasswordPage],
        getEntityScopeForPage(EntityType.COMMUNITY),
        noRedirectBehavior,
        false,
        null,
        true
      ),
      new PageDescriptor(
        EndUserPages.VerifyUserEmailPage,
        endUserModuleRegistry[EndUserComponent.VERIFY_USER_EMAIL_PAGE],
        pageDescriptorUrlPath[EndUserPages.VerifyUserEmailPage],
        getEntityScopeForPage(EntityType.COMMUNITY)
      ),
      new PageDescriptor(
        EndUserPages.UserSettingsPage,
        endUserModuleRegistry[EndUserComponent.USER_SETTINGS_PAGE],
        pageDescriptorUrlPath[EndUserPages.UserSettingsPage],
        getEntityScopeForPage(EntityType.COMMUNITY),
        userRequiredRedirectBehavior
      ),
      new PageDescriptor(
        EndUserPages.UserBadgesPage,
        endUserModuleRegistry[EndUserComponent.USER_BADGES_PAGE],
        pageDescriptorUrlPath[EndUserPages.UserBadgesPage],
        getEntityScopeForPage(EntityType.COMMUNITY)
      ),
      new PageDescriptor(
        EndUserPages.KhorosSignInPage,
        endUserModuleRegistry[EndUserComponent.KHOROS_SIGN_IN_PAGE],
        pageDescriptorUrlPath[EndUserPages.KhorosSignInPage],
        getEntityScopeForPage(EntityType.COMMUNITY),
        noRedirectBehavior,
        false,
        null,
        true
      )
    ];
  }

  getWidgetDescriptors(): WidgetDescriptor[] {
    return [
      {
        id: EndUserComponent.FORGOT_PASSWORD_WIDGET,
        component: dynamic(
          () => import('../components/authentication/ForgotPasswordWidget/ForgotPasswordWidget')
        )
      },
      {
        id: EndUserComponent.LOGIN_WIDGET,
        component: dynamic(() => import('../components/authentication/LoginWidget/LoginWidget'))
      },
      {
        id: EndUserComponent.REGISTRATION_WIDGET,
        component: dynamic(
          () => import('../components/authentication/RegistrationWidget/RegistrationWidget')
        )
      },
      {
        id: EndUserComponent.RESET_PASSWORD_WIDGET,
        component: dynamic(
          () => import('../components/authentication/ResetPasswordWidget/ResetPasswordWidget')
        )
      },
      {
        id: EndUserComponent.USER_ONLINE_LIST_FOR_COMMUNITY_WIDGET,
        component: dynamic(
          () =>
            import(
              '../components/users/UserOnlineListForCommunityWidget/UserOnlineListForCommunityWidget'
            ),
          {
            ssr: false
          }
        ),
        editor: {
          form: dynamic(
            () =>
              import(
                '../components/users/UserOnlineListForCommunityWidgetEditor/UserOnlineListForCommunityWidgetEditor'
              )
          )
        },
        allowedPages: []
      },
      {
        id: EndUserComponent.USER_PROFILE_WIDGET,
        component: dynamic(() => import('../components/users/UserProfileWidget/UserProfileWidget'))
      },
      {
        id: EndUserComponent.USER_SETTINGS_WIDGET,
        component: dynamic(
          () => import('../components/users/UserSettingsWidget/UserSettingsWidget')
        )
      },
      {
        id: EndUserComponent.MANAGE_USERS_WIDGET,
        component: dynamic(() => import('../components/users/ManageUsersWidget/ManageUsersWidget'))
      },
      {
        id: EndUserComponent.USER_BADGES_WIDGET,
        component: dynamic(() => import('../components/badges/UserBadgesWidget/UserBadgesWidget'))
      },
      {
        id: EndUserComponent.KHOROS_SIGN_IN_WIDGET,
        component: dynamic(
          () => import('../components/authentication/KhorosSignInWidget/KhorosSignInWidget')
        )
      }
    ];
  }
}
