// noinspection TypeScriptUnresolvedVariable,TypeScriptValidateTypes

import type {
  BooleanEntityCustomFormField,
  DateTimeEntityCustomFormField,
  EntityCustomFormField,
  FloatEntityCustomFormField,
  FloatInputField,
  FloatPossibleValue,
  FloatRadioField,
  FloatSelectField,
  IntEntityCustomFormField,
  IntInputField,
  IntPossibleValue,
  IntRadioField,
  IntSelectField,
  LongEntityCustomFormField,
  RelativeDate,
  StringEntityCustomFormField,
  StringInputField,
  StringPossibleValue,
  StringRadioField,
  StringSelectField
} from '@aurora/shared-generated/types/graphql-schema-types';
import {
  StringCustomFormFieldType,
  CustomFieldValueType,
  DateTimeCustomFormFieldType,
  DateUnit,
  FormInputType,
  FloatCustomFormFieldType,
  IntCustomFormFieldType
} from '@aurora/shared-generated/types/graphql-schema-types';
import { FormInputFieldInputType } from '../../components/form/enums';
import type { PossibleValueTypes } from '../../components/form/types';

export interface DateTimeValue {
  dateTime: Date;
}

export function getRelativeDate(
  relativeDate: RelativeDate,
  forward: boolean,
  fromDate: Date
): Date {
  const { unit, duration } = relativeDate;
  switch (unit) {
    case DateUnit.Day: {
      return new Date(
        fromDate.setDate(forward ? fromDate.getDate() + duration : fromDate.getDate() - duration)
      );
    }
    case DateUnit.Month: {
      return new Date(
        fromDate.setMonth(forward ? fromDate.getMonth() + duration : fromDate.getMonth() - duration)
      );
    }
    case DateUnit.Year: {
      return new Date(
        fromDate.setFullYear(
          forward ? fromDate.getFullYear() + duration : fromDate.getFullYear() - duration
        )
      );
    }
  }
}

export function getFormFieldInputType(inputType: FormInputType): FormInputFieldInputType {
  if (inputType === FormInputType.Text) {
    return FormInputFieldInputType.TEXT;
  } else if (inputType === FormInputType.Hidden) {
    return FormInputFieldInputType.HIDDEN;
  }
  return FormInputFieldInputType.TEXT;
}

function getDefaultValueForCustomField(
  field: EntityCustomFormField
): string | DateTimeValue | boolean {
  const { valueType: formFieldType } = field;
  const customFormFieldType: CustomFieldValueType = formFieldType as CustomFieldValueType;
  switch (customFormFieldType) {
    case CustomFieldValueType.Boolean: {
      const { booleanDefaultValue, booleanSetting } = field as BooleanEntityCustomFormField;
      return booleanSetting?.value ?? booleanDefaultValue ?? false;
    }
    case CustomFieldValueType.Datetime: {
      const { dateTimeDefaultValue, dateTimeField, dateTimeSetting } =
        field as DateTimeEntityCustomFormField;
      const dateString = dateTimeSetting?.value ?? dateTimeDefaultValue;
      const { dateTimeType } = dateTimeField;
      switch (dateTimeType) {
        case DateTimeCustomFormFieldType.Input: {
          return `${dateString ?? ''}`;
        }
        default: {
          return dateString
            ? ({ dateTime: new Date(dateString as string) } as unknown as DateTimeValue)
            : ({ dateTime: new Date() } as unknown as DateTimeValue);
        }
      }
    }
    case CustomFieldValueType.Float: {
      const { floatDefaultValue, floatSetting } = field as FloatEntityCustomFormField;
      return `${floatSetting?.value ?? floatDefaultValue ?? ''}`;
    }
    case CustomFieldValueType.Int: {
      const { intDefaultValue, intSetting } = field as IntEntityCustomFormField;
      return `${intSetting?.value ?? intDefaultValue ?? ''}`;
    }
    case CustomFieldValueType.Long: {
      const { longDefaultValue, longSetting } = field as LongEntityCustomFormField;
      return `${longSetting?.value ?? longDefaultValue ?? ''}`;
    }
    case CustomFieldValueType.String: {
      const { stringDefaultValue, stringSetting } = field as StringEntityCustomFormField;
      return stringSetting?.value ?? stringDefaultValue ?? '';
    }
  }
  return '';
}

function getDefaultValuesForCustomFields(
  customFormFields: Array<EntityCustomFormField>
): Record<string, unknown> {
  const defaultValues: Record<string, unknown> = {};
  customFormFields.forEach(field => {
    const { name: customName } = field;
    defaultValues[customName] = getDefaultValueForCustomField(field);
  });
  return defaultValues;
}

/**
 * Checks if the provided value conforms to the types defined by `PossibleValueTypes`.
 * @param value The value for which the type is to be checked.
 * @returns Returns `true` if the type of the value conforms to the type of `PossibleValueTypes`, otherwise returns `false`.
 */
function isPossibleValueTypes(
  value: StringPossibleValue | IntPossibleValue | FloatPossibleValue
): value is PossibleValueTypes {
  return (
    typeof value === 'object' &&
    value !== null &&
    '__typename' in value &&
    (value.__typename === 'StringPossibleValue' ||
      value.__typename === 'FloatPossibleValue' ||
      value.__typename === 'IntPossibleValue')
  );
}

/**
 * Checks if all elements in the provided array conform to the types defined by `PossibleValueTypes`.
 * @param possibleValues An array of values to be checked.
 * @returns Returns `true` if all elements in the array conform to the type of `PossibleValueTypes`, otherwise returns `false`.
 */
function isArrayOfPossibleValueType(
  possibleValues: (StringPossibleValue | IntPossibleValue | FloatPossibleValue)[]
): boolean {
  return (
    Array.isArray(possibleValues) && possibleValues.every(value => isPossibleValueTypes(value))
  );
}

/**
 * @param customField the entity custom form field.
 * @returns Return the possible values array for a custom field or null.
 */

function getPossibleValuesForCustomField(
  customField: EntityCustomFormField
): PossibleValueTypes[] | null {
  const { valueType: formFieldType } = customField;
  const customFormFieldType: CustomFieldValueType = formFieldType as CustomFieldValueType;
  switch (customFormFieldType) {
    case CustomFieldValueType.String: {
      const { stringField } = customField as StringEntityCustomFormField;
      const { stringType } = stringField;
      const formFieldControlType: StringCustomFormFieldType =
        stringType as StringCustomFormFieldType;

      switch (formFieldControlType) {
        case StringCustomFormFieldType.Select: {
          const { stringSelectValues } = stringField as StringSelectField;
          return stringSelectValues;
        }
        case StringCustomFormFieldType.Radio: {
          const { stringRadioValues } = stringField as StringRadioField;
          return stringRadioValues;
        }
        case StringCustomFormFieldType.Input: {
          const { stringInputValues } = stringField as StringInputField;
          return stringInputValues;
        }
      }
      break;
    }

    case CustomFieldValueType.Int: {
      const { intField } = customField as IntEntityCustomFormField;
      const { intType } = intField;
      const formFieldControlType: IntCustomFormFieldType = intType as IntCustomFormFieldType;

      switch (formFieldControlType) {
        case IntCustomFormFieldType.Select: {
          const { intSelectValues } = intField as IntSelectField;
          return intSelectValues;
        }
        case IntCustomFormFieldType.Radio: {
          const { intRadioValues } = intField as IntRadioField;
          return intRadioValues;
        }

        case IntCustomFormFieldType.Input: {
          const { intInputValues } = intField as IntInputField;
          return intInputValues;
        }
      }
      break;
    }

    case CustomFieldValueType.Float: {
      const { floatField } = customField as FloatEntityCustomFormField;
      const { floatType } = floatField;
      const formFieldControlType: FloatCustomFormFieldType = floatType as FloatCustomFormFieldType;

      switch (formFieldControlType) {
        case FloatCustomFormFieldType.Select: {
          const { floatSelectValues } = floatField as FloatSelectField;
          return floatSelectValues;
        }
        case FloatCustomFormFieldType.Radio: {
          const { floatRadioValues } = floatField as FloatRadioField;
          return floatRadioValues;
        }
        case FloatCustomFormFieldType.Input: {
          const { floatInputValues } = floatField as FloatInputField;
          return floatInputValues;
        }
      }
    }
  }
  return null;
}

/**
 * @param customField the entity custom form field
 * @returns Returns true if the custom field possesses attributes of type PossibleValueTypes, otherwise returns false.
 */
function isCustomFieldPossibleValueType(customField: EntityCustomFormField): boolean {
  const possibleValues = getPossibleValuesForCustomField(customField);
  return isArrayOfPossibleValueType(possibleValues);
}

export {
  getDefaultValuesForCustomFields,
  getDefaultValueForCustomField,
  isCustomFieldPossibleValueType,
  getPossibleValuesForCustomField,
  isPossibleValueTypes,
  isArrayOfPossibleValueType
};
