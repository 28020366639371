import React, { useEffect } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { getIconPath } from '../../../icons';
import IconTypes from '../../../public/static/graphics/processed/enums';
import useCrossOriginAttribute from '../../useCrossOriginAttribute';
import ConditionalWrapper from '../ConditionalWrapper/ConditionalWrapper';
import { IconColor, IconSize } from './enums';
import localStyles from './Icon.module.pcss';
import type IconProps from './IconProps';
import { cssVar } from '../../../helpers/styles/CssVariableHelper';
import { getLog } from '@aurora/shared-utils/log';

/** We can't render these in the 'use' directive or they will not appear correctly. */
const clipPathIcons: Set<IconTypes | string> = new Set([IconTypes.DesignerThemesIcon]);

const log = getLog(module);

/**
 * Renders an Icon with set color and size. Can be in a "frame" or stand alone.
 *
 * @author Adam Ayres, Willi Hyde
 */
const Icon: React.FC<React.PropsWithChildren<IconProps>> = ({
  icon,
  iconResolver,
  size,
  useFrame = false,
  color = IconColor.BODY_COLOR,
  className,
  testId,
  title,
  ariaLabel = ''
}) => {
  const cx = useClassNameMapper(localStyles);
  const crossOrigin = useCrossOriginAttribute();

  useEffect(() => {
    if (useFrame && cssVar(`${color}-h`, null) === null) {
      log.error(`useFrame is unsupported with the icon color ${color} and frame will not be used.`);
    }
  });

  if (iconResolver) {
    return iconResolver();
  }

  const classes =
    size === IconSize.FROM_CSS
      ? [className]
      : [className, `lia-g-icon-size-${size} lia-size-${size}`];

  const style = {
    '--lia-local-icon-color': `var(${color})`,
    '--lia-local-icon-color-h': `var(${color}-h)`,
    '--lia-local-icon-color-s': `var(${color}-s)`,
    '--lia-local-icon-color-l': `var(${color}-l)`
  } as React.CSSProperties;

  const href = getIconPath(icon);
  const splitIcon = href?.split('/');
  const hasPartial = splitIcon && splitIcon.length > 1 && splitIcon.at(-1).includes('.svg#');
  const canUseUse = hasPartial && !clipPathIcons.has(icon);

  return (
    <ConditionalWrapper condition={useFrame} className={cx(classes, 'lia-frame')} style={style}>
      <svg
        className={cx(!useFrame ? classes : null, 'lia-icon')}
        data-testid={testId ?? 'Icon'}
        style={style}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(ariaLabel ? { 'aria-label': ariaLabel } : { 'aria-hidden': true })}
      >
        {title && <title>{title}</title>}
        {canUseUse ? <use href={href} /> : <image href={href} crossOrigin={crossOrigin(href)} />}
      </svg>
    </ConditionalWrapper>
  );
};

export default Icon;
