export enum FormFieldVariant {
  ASYNC_SEARCHABLE_SELECT = 'asyncSearchableSelect',
  ATTACHMENTS = 'attachmentEditor',
  AVATAR_EDITOR = 'avatarEditor',
  BACKGROUND_IMAGE_PROPERTIES = 'backgroundImageWithProperties',
  BOUNDARY_PADDING = 'boundaryPadding',
  CHECK = 'check',
  CHECK_WITH_TEXT_FIELD = 'checkWithTextField',
  COLOR_PICKER = 'tooltipColorPicker',
  COLOR_SWATCH = 'colorSwatch',
  COVER_IMAGE_SUBJECT = 'coverImageWithSubject',
  CONTENT_WRAPPER = 'contentWrapper',
  DATETIME = 'datetime',
  DETAILED_SELECT = 'detailedSelect',
  DROPDOWN_SELECT = 'dropdownSelect',
  FILE = 'file',
  GROUP_HUB_DISCUSSION_STYLES = 'groupHubDiscussionStyles',
  ICON_RADIO = 'iconRadio',
  IMAGE_UPLOAD = 'imageUpload',
  INPUT = 'input',
  INPUT_GROUP = 'inputGroup',
  LOCATION = 'location',
  LANGUAGE_SELECTION_FIELD = 'languageSelectionField',
  MESSAGE_SEARCH = 'messageSearch',
  MULTI_CHECK_BOX = 'multiCheckBox',
  MULTI_SELECT = 'multiSelect',
  MULTI_IMAGE_UPLOAD = 'multiImageUpload',
  NEW_PASSWORD = 'newPassword',
  NODE_CHECKBOX_TREE = 'nodeCheckboxTree',
  PASSWORD = 'password',
  PERMISSION_RADIO = 'permissionRadio',
  PILL_RADIO = 'pillRadio',
  PLACEHOLDER_FORM_FIELD = 'placeholderFormField',
  RADIO = 'radio',
  RANGE = 'range',
  RTE = 'richTextEditor',
  SEARCH = 'search',
  SEARCHABLE_SELECT = 'searchableSelect',
  SELECT = 'select',
  TAGS = 'tagEditor',
  TEXT_AREA = 'textarea',
  CREATABLE_MULTI_SELECT = 'creatableMultiSelect',
  CREATABLE_SINGLE_SELECT = 'creatableSingleSelect',
  NODE_PICKER = 'nodePicker',
  MULTI_NODE_PICKER = 'multiNodePicker',
  RADIO_WITH_CUSTOM_INPUT = 'radioWithCustomInput',
  DATE_TIME_RANGE_SELECT = 'dateTimeRangeSelect',
  DATE_INPUT = 'dateInput',
  SEARCH_WITH_DROPDOWN = 'searchWithDropdown',
  GROUPED_DROPDOWN_SELECT = 'groupedDropdownSelect',
  VIDEO_URL_WITH_PREVIEW = 'videoUrlWithPreview',
  DRAGGABLE_MUTLI_SELECT_FIELD = 'draggableMultiSelectField',
  CUSTOM_FIELD = 'customField',
  GUIDE_PICKER = 'guidesPicker'
}

export enum FormButtonType {
  SUBMIT = 'submit',
  RESET = 'reset',
  BUTTON = 'button'
}

export enum FormCheckInputType {
  CHECKBOX = 'checkbox',
  SWITCH = 'switch'
}

export enum FormInputFieldInputType {
  COLOR = 'color',
  DATE = 'date',
  DATE_TIME_LOCAL = 'datetime-local',
  EMAIL = 'email',
  FILE = 'file',
  HIDDEN = 'hidden',
  MONTH = 'month',
  NUMBER = 'number',
  PASSWORD = 'password',
  VIEWABLE_PASSWORD = 'viewable_password',
  SEARCH = 'search',
  TEL = 'tel',
  TEXT = 'text',
  TIME = 'time',
  URL = 'url',
  WEEK = 'week',
  CHECKBOX = 'checkbox'
}

export enum FormActionButtonsPosition {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
  STRETCH = 'stretch',
  FULL_WIDTH = 'full-width'
}

export enum FormInputFieldViewVariant {
  NO_BORDER = 'no-border'
}

export enum ToggleableState {
  OPEN = 'open',
  CLOSED = 'closed'
}

export enum FormGroupFieldType {
  ROW = 'row',
  COL = 'col',
  FIELDSET = 'fieldset',
  FORM_ROW = 'formRow'
}

export enum FormGroupFieldSeparator {
  DIVIDER = 'divider'
}

export enum FormActionButtonBarPosition {
  STICKY = 'sticky'
}

export enum FormGroupAsElement {
  ROW = 'row',
  COL = 'col'
}

export enum BoundaryPaddingLocation {
  TOP = 'top',
  BOTTOM = 'bottom'
}

export enum CheckFieldVariant {
  STANDARD = 'standard',
  STRETCHED = 'stretched'
}

export enum MultiCheckFieldVariant {
  STANDARD = 'standard',
  ACCORDION = 'accordion'
}

export enum MenuPlacementLocation {
  AUTO = 'auto',
  TOP = 'top',
  BOTTOM = 'bottom'
}
